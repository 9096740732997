import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { _axiosSignup } from '../API Calls/functions';

export const signup = createAsyncThunk(
  'auth/signup',
  async ({ email, password }, thunkAPI) => {
    try {
      var response = await _axiosSignup(email, password);
      return response.data;
    } catch (error) {
      console.log(error);
      if (error.response.data.errors !== undefined) {
        console.log('Validation errors:');
        console.log(error.response.data);
        const allErrorsArr = Object.values(error.response.data.errors).flat();
        console.log(allErrorsArr);

        console.log(allErrorsArr);
        return thunkAPI.rejectWithValue(allErrorsArr);
      }

      return thunkAPI.rejectWithValue([error.response.data]);
    }
  }
);

const initialState = {
  token: null,
  isLoading: false,
  isError: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
      state.token = null;
      state.isLoading = false;
      state.isError = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signup.fulfilled, (state, action) => {
        state.token = action.payload;
        state.isLoading = false;
        state.isError = [];
      })
      .addCase(signup.pending, (state) => {
        state.token = null;
        state.isLoading = true;
        state.isError = [];
      })
      .addCase(signup.rejected, (state, action) => {
        state.token = null;
        state.isLoading = false;
        state.isError = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
