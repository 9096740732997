import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { _axiosGetUserByEmail } from '../API Calls/functions';

export const getUser = createAsyncThunk(
  'login/getUser',
  async ({ email, token }, thunkAPI) => {
    try {
      var response = await _axiosGetUserByEmail(email, token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  user: null,
  isLoading: false,
  isError: [],
};

export const userSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    logout: (state) => {
      state.isError = [];
      state.user = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
        state.user = null;
        state.isError = [];
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.isError = [];
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.user = null;
        state.isError = action.payload;
      });
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
