import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import authSlice from './authSlice';
import userSlice from './userSlice';
import billsSlice from './billsSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  login: userSlice,
  billsData: billsSlice,
});

const persistConfig = {
  key: 'root',
  storage: storageSession, // Corrected import statement
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check
    }),
});

export const persistor = persistStore(store);
