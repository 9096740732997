import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _axiosDeleteBill, _axiosUpdateBill } from '../../API Calls/functions';
import { getBills } from '../../store/billsSlice';

import NewBill from './AddNewBill/NewBill';
import { TiDeleteOutline } from 'react-icons/ti';

const Bill = ({ bill, month, year, frequencies }) => {
  const [billFrequency, setBillFrequency] = useState('');

  const billDate = new Date(bill.payBy);
  const todaysDate = new Date().toISOString();

  const [billCompleted, setBillCompleted] = useState(bill.completed);

  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.login);

  const dispatch = useDispatch();

  const billCompletedChangeHandler = async () => {
    let newBill = { ...bill };
    newBill.completed = !billCompleted;
    if (!billCompleted === true) {
      newBill.completedDate = new Date().toISOString();
    } else {
      newBill.completedDate = null;
    }
    setBillCompleted(!billCompleted);

    const response = await _axiosUpdateBill(newBill, token);
    console.log('Updated bill');
    console.log(response.data);

    const userId = user.id;
    dispatch(getBills({ userId, token }));
  };

  const removeBillHandler = async (e) => {
    const confirm = window.confirm(
      `Do you want to remove bill "${bill.billName}" due: ${
        bill.payBy.split('T')[0]
      }`
    );
    if (confirm) {
      var response = await _axiosDeleteBill(token, bill.id);
      console.log(response);
      window.alert(response.data);
      const userId = user.id;
      dispatch(getBills({ userId, token }));
    }
  };

  useEffect(() => {
    const setUpFrequency = async () => {
      if (frequencies.length > 0) {
        const frequency = frequencies.filter((frequency) =>
          frequency.id.includes(bill.frequencyId)
        )[0].name;
        setBillFrequency(
          frequency.charAt(0).toUpperCase() + frequency.slice(1)
        );
      }
    };
    setUpFrequency();
  }, [token, billFrequency, bill, frequencies]);

  return (
    <>
      <div
        className=' border-bottom border-2 border-light bg-dark bg-opacity-50 d-flex justify-content-between align-items-center'
        style={{ borderRadius: '10px' }}
      >
        <div className=' text-success'>
          {billFrequency}
          <small className='text-primary'></small>
        </div>
        <h5 className='text-center text-light'>{bill.billName}</h5>
        <div className=' d-flex'>
          <div>
            <span>
              <NewBill month={month} year={year} bill={bill} path='Edit bill' />
            </span>
          </div>
          <div>
            <span onClick={removeBillHandler}>
              <TiDeleteOutline size={20} className='text-warning mx-2' />
            </span>
          </div>
        </div>
      </div>

      <div
        className={`mt-3 d-flex justify-content-between align-items-center ${
          billCompleted && 'text-decoration-line-through'
        }`}
      >
        <h6
          className={
            billDate.toISOString() <= todaysDate
              ? 'text-danger'
              : 'text-success'
          }
        >
          €{bill.amount}
        </h6>
        <h6
          className={
            billDate.toISOString() <= todaysDate
              ? 'text-danger'
              : 'text-success'
          }
        >
          {billDate.toLocaleDateString()}
        </h6>
        <div className='form-check form-switch'>
          <input
            className='form-check-input'
            type='checkbox'
            id='flexSwitchCheckDefault'
            checked={billCompleted}
            onChange={() => {
              billCompletedChangeHandler();
            }}
          />
        </div>
      </div>
      {bill.comment !== null && (
        <div className='row mt-2 '>
          <div className='col-10 mx-auto card bg-danger bg-opacity-10 p-2 ml'>
            <p className='card-text text-center'>{bill.comment}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Bill;
