import React, { useEffect, useState } from 'react';
import { _axiosRegisterUser } from '../../API Calls/functions';

import Loader from '../Loader/Loader';

import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../../store/authSlice';
import { getUser } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';
import { logout as removeToken } from '../../store/authSlice';
import { logout as removeUser } from '../../store/userSlice';
import { logout as removeBills } from '../../store/billsSlice';

const LoginRegisterForm = ({ path }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPassworConfirm] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [allErrors, setAllErrors] = useState([]);

  const navigate = useNavigate();

  const {
    token,
    isLoading: isLoadingAuth,
    isError: isAuthError,
  } = useSelector((state) => state.auth);
  const {
    user,
    isLoading: isLoadingLogin,
    isError: isLoginError,
  } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  //---
  const formSubmitChandler = async (e) => {
    e.preventDefault();
    setResultMessage('');
    setAllErrors([]);
    setIsError(false);

    if (path === 'Register') {
      const userObj = {
        username,
        email,
        password,
        confirmPassword: passwordConfirm,
      };
      const response = await _axiosRegisterUser(userObj);
      console.log(response);
      //#region
      ///Code for dealing with all the result from API----------------
      if (response.status === 200) {
        console.log('Registration successfull');
        navigate('/Auth/login');
      } else {
        if (response.response.status === 404) {
          setIsError(true);
          setResultMessage(response.message);
        }
        if (response.response.status === 400) {
          setIsError(true);

          setResultMessage(response.response.data.title);
          const errArr = Object.values(response.response.data.errors);
          const errArrFlat = errArr.flat();
          setAllErrors(errArrFlat);
        }
      }
      ///---------------------------------------------------------------
      //#endregion
    } else if (path === 'Login') {
      dispatch(signup({ email, password }));
    } else if (path === 'Logout') {
      dispatch(removeToken());
      dispatch(removeUser());
      dispatch(removeBills());
    }
  };
  //getting user after receiving the token when loggin in
  useEffect(() => {
    if (token !== null && path === 'Login') {
      dispatch(getUser({ email, token }));
    }
  }, [token, dispatch, email, path]);

  //ressetting the error messages when the path changes
  useEffect(() => {
    setIsError(false);
    setAllErrors([]);
    setResultMessage('');
    setEmail('');
    setUsername('');
    setPassword('');
    setPassworConfirm('');
  }, [path]);

  //adding errors from the global state
  useEffect(() => {
    if (isAuthError.length > 0) {
      setIsError(true);
      setResultMessage('Validation errors:');
      setAllErrors(isAuthError);
    }
    if (isLoginError.length > 0) {
      setIsError(true);
      setResultMessage('Validation errors:');
      setAllErrors(isLoginError);
    }
  }, [isAuthError, isLoginError]);

  //redirect from register and login when user object present
  useEffect(() => {
    if ((path === 'Login' || path === 'Register') && user !== null) {
      navigate('/');
    }
    if (path === 'Logout' && user === null) {
      navigate('/');
    }
  }, [user, path, navigate]);
  return (
    <div>
      <div className='card bg-dark btn-outline-primary mt-5'>
        <div className='card-body'>
          <div className='card-title'>
            <h2>{path}</h2>
          </div>
          <form onSubmit={formSubmitChandler}>
            <div className='card-text mt-4'>
              {/*  */}
              <div className='mb-3'>
                <label
                  htmlFor='username'
                  className='form-label'
                  hidden={path !== 'Register'}
                >
                  Username:
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='username'
                  hidden={path !== 'Register'}
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </div>
              {/*  */}
              <div className='mb-3'>
                <label
                  htmlFor='email'
                  className='form-label'
                  hidden={path === 'Logout'}
                >
                  Email address
                </label>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  placeholder='name@example.com'
                  hidden={path === 'Logout'}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              {/*  */}
              <div className='mb-3'>
                <label
                  htmlFor='password'
                  className='form-label'
                  hidden={path === 'Logout'}
                >
                  Password
                </label>
                <input
                  type='password'
                  name='password'
                  className='form-control'
                  id='password'
                  hidden={path === 'Logout'}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              {/*  */}
              <div className='mb-3'>
                <label
                  htmlFor='confirmPassword'
                  className='form-label'
                  hidden={path !== 'Register'}
                >
                  Confirm password
                </label>
                <input
                  type='password'
                  name='passwordConfirm'
                  className='form-control'
                  id='confirmPassword'
                  hidden={path !== 'Register'}
                  value={passwordConfirm}
                  onChange={(e) => {
                    setPassworConfirm(e.target.value);
                  }}
                />
              </div>
              {/*  */}
              {resultMessage.length > 0 && (
                <div className='card bg-danger text-end p-3 mb-3'>
                  <div className='card-text'>
                    <div
                      className={
                        isError === true ? 'text-warning' : 'text-success'
                      }
                    >
                      <h5 className='mb-4'>{resultMessage}</h5>
                    </div>

                    {allErrors.length > 0 && (
                      <div
                        className={
                          isError === true ? 'text-warning' : 'text-success'
                        }
                      >
                        {allErrors.map((err, i) => (
                          <h6 key={i}>{err}</h6>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/*  */}

              <div className='mb-3 d-flex justify-content-end'>
                <button
                  disabled={isLoadingAuth || isLoadingLogin}
                  type='submit'
                  className='btn btn-primary'
                >
                  {path}
                </button>
              </div>
              {isLoadingAuth && <Loader />}
              {isLoadingLogin && <Loader />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginRegisterForm;
