import React from 'react';
import './loader.css';

const Loader = () => {
  return (
    <div className='container '>
      <div className='row '>
        <div className='col-sm-12 text-center'>
          <div className='loader1'>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
