import React from 'react';
import LoginRegisterForm from './LoginRegisterForm';
import { motion } from 'framer-motion';

const LoginRegister = ({ path }) => {
  return (
    <motion.div
      initial={{ scaleY: 0 }}
      animate={{
        scaleY: 1,
      }}
      transition={{
        delay: 0.3,
        type: 'spring',
        stiffness: 200,
        damping: 20,
      }}
    >
      <LoginRegisterForm path={path} />
    </motion.div>
  );
};

export default LoginRegister;
