import React, { useCallback, useEffect, useState } from 'react';
import { getAllBillsForGivenMonthAndYear } from '../../helperFunctions/billsSorting';
import { useSelector } from 'react-redux';
import Bill from './Bill';
import { _axiosGetAllFrequencies } from '../../API Calls/functions';
import { motion } from 'framer-motion';

const BillsForMonth = ({ year, month }) => {
  const [billsForThisMonth, setBillsForThisMonth] = useState([]);
  const [frequencies, setFrequencies] = useState([]);

  const { bills } = useSelector((state) => state.billsData);
  const { token } = useSelector((state) => state.auth);

  const getAllFrequencies = useCallback(async () => {
    const response = await _axiosGetAllFrequencies(token);
    return response.data;
  }, [token]);

  useEffect(() => {
    setBillsForThisMonth(getAllBillsForGivenMonthAndYear(month, year, bills));
  }, [month, year, bills]);
  useEffect(() => {
    if (token !== null) {
      const getFrequencies = async () => {
        var result = await getAllFrequencies();
        setFrequencies(result);
      };
      getFrequencies();
    }
  }, [getAllFrequencies, token]);
  return (
    <div>
      {billsForThisMonth.map((bill) => (
        <motion.li
          key={bill.id}
          className='list-group-item  my-2 rounded border-1 border-light'
          initial={{ scale: 0.5, opacity: 0.5 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{
            delay: 0,
            type: 'spring',
            stiffness: 400,
            damping: 15,
          }}
          whileHover={{
            scale: 1.1,
            filter: 'brightness(1.4)',
          }}
        >
          <Bill
            bill={bill}
            month={month}
            year={year}
            frequencies={frequencies}
          />
        </motion.li>
      ))}
    </div>
  );
};

export default BillsForMonth;
