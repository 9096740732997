import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardTitle,
  Row,
} from 'reactstrap';
import {
  _axiosAcceptRequest,
  _axiosRejectRequest,
} from '../../API Calls/functions';
import { getBills } from '../../store/billsSlice';

const Request = ({ checkRequestResponseDataForMasterUser }) => {
  const { token } = useSelector((selector) => selector.auth);
  const { user } = useSelector((selector) => selector.login);

  const dispach = useDispatch();

  const rejectTheRequest = async () => {
    const confirm = window.confirm(
      'Are you sure you want to reject the request?'
    );
    if (confirm) {
      const response = await _axiosRejectRequest(
        token,
        user.id,
        checkRequestResponseDataForMasterUser.childUserEmailAddress
      );
      console.log(response);

      if (response.status === 200) {
        const userId = user.id;
        dispach(getBills({ userId, token }));
      }
    }
  };

  const acceptTheRequestHandler = async () => {
    const confirm = window.confirm(
      'Are you sure you want to accept the request?'
    );
    if (confirm) {
      const response = await _axiosAcceptRequest(
        token,
        user.id,
        checkRequestResponseDataForMasterUser.childUserEmailAddress
      );

      if (response.status === 200) {
        const userId = user.id;
        dispach(getBills({ userId, token }));
      }
    }
  };
  return (
    <Row className='mx-auto'>
      <Card className=' border-2 border-danger-subtle col-12 mx-auto'>
        <CardHeader>
          <CardTitle tag='h6' className='text-center'>
            <span className='text-info'>
              {checkRequestResponseDataForMasterUser.childUserEmailAddress}
            </span>{' '}
            <br /> has requested to join your connect to your account.
          </CardTitle>
        </CardHeader>
        <CardFooter>
          <Row>
            <Button
              color='outline-primary'
              className='my-1'
              onClick={acceptTheRequestHandler}
            >
              Accept
            </Button>
            <Button
              color='outline-warning'
              className='my-1'
              onClick={rejectTheRequest}
            >
              Reject
            </Button>
          </Row>
        </CardFooter>
      </Card>
    </Row>
  );
};

export default Request;
