import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { _axiosGetBillsByUserId } from '../API Calls/functions';

export const getBills = createAsyncThunk(
  'userBills,getBills',
  async ({ userId, token }, thunkApi) => {
    try {
      var response = await _axiosGetBillsByUserId(userId, token);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  bills: null,
  isLoading: false,
  isError: [],
};

export const billsSlice = createSlice({
  name: 'userBills',
  initialState,
  reducers: {
    logout: (state) => {
      state.bills = null;
      state.isError = [];
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBills.pending, (state) => {
        state.bills = null;
        state.isError = [];
        state.isLoading = true;
      })
      .addCase(getBills.fulfilled, (state, action) => {
        state.bills = action.payload;
        state.isError = [];
        state.isLoading = false;
      })
      .addCase(getBills.rejected, (state, action) => {
        state.bills = null;
        state.isError = action.payload;
        state.isLoading = false;
      });
  },
});

export const { logout } = billsSlice.actions;

export default billsSlice.reducer;
