import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBills } from '../store/billsSlice';
import BillList from './Bills/BillList';
import Loader from './Loader/Loader';
import {
  _axiosCreateNextMonthBills,
  _axiosDeleteLastCreatedMonth,
  _axiosGetAllConnectedChildUsers,
} from '../API Calls/functions';
import { ButtonGroup } from 'reactstrap';
import { motion } from 'framer-motion';
import ConnectedChildAccounts from '../ConnectedChildAccounts/ConnectedChildAccounts';

export const Home = () => {
  const [childUsersConnected, setChildUsersConnected] = useState([]);

  const dispatch = useDispatch();

  const {
    user,
    isLoading: isLoadingUser,
    isError: isErrorUser,
  } = useSelector((state) => state.login);
  const {
    token,
    isLoading: isLoadingToken,
    isError: isErrorToken,
  } = useSelector((state) => state.auth);
  const { bills } = useSelector((state) => state.billsData);

  useEffect(() => {
    if (user !== null) {
      const userId = user.id;
      dispatch(getBills({ userId, token }));
    }
  }, [user, dispatch, token]);
  // monthly bills functions-------------------------------
  const createNextMonthHandler = async () => {
    const confirm = window.confirm(
      'The bills for the next month will be created.'
    );
    if (confirm) {
      const result = await _axiosCreateNextMonthBills(token, user.id);
      console.log(result);
      const userId = user.id;
      dispatch(getBills({ userId, token }));
    }
  };
  const deleteLastMonthHandler = async () => {
    const confirm = window.confirm(
      'Are you sure you want to delete all bills for the last month?'
    );
    if (confirm) {
      const result = await _axiosDeleteLastCreatedMonth(token, user.id);
      console.log(result);
      const userId = user.id;
      dispatch(getBills({ userId, token }));
    }
  };
  // monthly bills functions----------------------------end
  //checking for connected child accounts------------------
  useEffect(() => {
    const checkForChildUsersAsync = async () => {
      var result = await _axiosGetAllConnectedChildUsers(token, user.id);

      if (result.status === 200) {
        setChildUsersConnected(result.data);
      }
    };
    if (bills !== null) {
      checkForChildUsersAsync();
    }
  }, [bills, token, user]);
  //checking for connected child accounts---------------end
  return (
    <>
      {isLoadingToken || isLoadingUser ? (
        <Loader />
      ) : (
        <>
          {user === null ? (
            <div className='text-center'>
              <h1>Welcome to the Bill Manager</h1>
              <h2 className='text-muted'>Please login</h2>
            </div>
          ) : (
            <>
              {bills !== null && (
                <>
                  {childUsersConnected.length > 0 && (
                    <ConnectedChildAccounts
                      childUsersConnected={childUsersConnected}
                    />
                  )}
                  <div className='row'>
                    <ButtonGroup>
                      <motion.button
                        type='button'
                        className='btn btn-outline-success col-6  mx-auto my-2'
                        onClick={createNextMonthHandler}
                        initial={{ scaleX: 0 }}
                        whileInView={{ scaleX: 1 }}
                        transition={{
                          delay: 0.3,
                          type: 'spring',
                          stiffness: 400,
                          damping: 15,
                        }}
                      >
                        Create next month bills
                      </motion.button>

                      <motion.button
                        type='button'
                        className='btn btn-outline-danger col-6  mx-auto my-2'
                        onClick={deleteLastMonthHandler}
                        initial={{ scaleX: 0 }}
                        whileInView={{ scaleX: 1 }}
                        transition={{
                          delay: 0.6,
                          type: 'spring',
                          stiffness: 400,
                          damping: 15,
                        }}
                      >
                        Delete last availble month bills
                      </motion.button>
                    </ButtonGroup>
                  </div>
                </>
              )}

              <BillList />
            </>
          )}
        </>
      )}
    </>
  );
};
