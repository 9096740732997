import React from 'react';
import { Table } from 'reactstrap';
import { IoPersonRemoveOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { _axiosDisconnectChildUserAsync } from '../API Calls/functions';
import { getBills } from '../store/billsSlice';

const ConnectedChildAccountsTable = ({ childUsersConnected }) => {
  const { user } = useSelector((state) => state.login);
  const { token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const diconnectUserHandler = async (childUserId) => {
    const disconnectRequest = {
      mainUserId: user.id,
      childUserId,
    };
    const confirm = window.confirm(`Disconnect user?`);
    if (confirm) {
      const response = await _axiosDisconnectChildUserAsync(
        token,
        disconnectRequest
      );
      if (response.status === 200) {
        const userId = user.id;
        dispatch(getBills({ userId, token }));
      }
    }
  };
  return (
    <Table hover responsive striped>
      <thead>
        <tr>
          <th>#</th>
          <th>Email</th>
          <th className='text-center'>Disconnect</th>
        </tr>
      </thead>
      <tbody>
        {childUsersConnected.map((childUser, i) => (
          <tr key={childUser.id}>
            <th scope='row'>{i + 1}</th>
            <td>{childUser.email}</td>
            <td className='text-center'>
              <IoPersonRemoveOutline
                className='text-danger'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  diconnectUserHandler(childUser.id);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ConnectedChildAccountsTable;
