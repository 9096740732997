import { Button, ButtonGroup, FormGroup, Row } from 'reactstrap';
import Loader from '../Loader/Loader';

const ConnectAccountsForm = ({
  setConnectAccounts,
  connectToAccountRequestHandler,
  masterPersonEmailAddress,
  setMasterPersonEmailAddress,
  connectRequestIsLoading,
  errorMessage,
}) => {
  return (
    <form
      onSubmit={connectToAccountRequestHandler}
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      transition={{
        delay: 1,
      }}
    >
      <FormGroup floating>
        <input
          type='email'
          required
          className='form-control'
          id='masterPersonEmail'
          value={masterPersonEmailAddress}
          onChange={(e) => {
            setMasterPersonEmailAddress(e.target.value);
          }}
        />
        <label htmlFor='masterUsername' className='form-label'>
          Email of the user that you want to connect your accoun to:
        </label>
      </FormGroup>
      <Row className='mx-auto'>
        {errorMessage.length > 0 && (
          <p className=' text-danger'>{errorMessage}</p>
        )}
        {connectRequestIsLoading ? (
          <Loader />
        ) : (
          <ButtonGroup>
            <Button color='outline-danger' type='submit'>
              Send connect request
            </Button>
            <Button
              color='outline-secondary'
              onClick={() => {
                setConnectAccounts(false);
              }}
            >
              Cancell
            </Button>
          </ButtonGroup>
        )}
      </Row>
    </form>
  );
};

export default ConnectAccountsForm;
