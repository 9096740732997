import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import {
  _axiosAddBill,
  _axiosGetAllFrequencies,
  _axiosUpdateBill,
} from '../../../API Calls/functions';
import { getBills } from '../../../store/billsSlice';
import { FaMagic } from 'react-icons/fa';
import Loader from '../../Loader/Loader';

const NewBillBody = ({ year, month, path, bill }) => {
  const [firstDayOfTheMonth, setFirstDayOfTheMonth] = useState('');
  const [lastDayOfTheMonth, setLastDayOfTheMonth] = useState('');
  const [frequencies, setFrequencies] = useState([]);
  const [id, setId] = useState('00000000-0000-0000-0000-000000000000');
  const [billName, setBillName] = useState('');
  const [payBy, setPayBy] = useState('');
  const [amount, setAmount] = useState('');
  const [frequencyId, setFrequencyId] = useState('');
  const [completed, setCompleted] = useState(false);
  const [completedDate, setCompletedDate] = useState(null);
  const [comment, setComment] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [isLoadingFrequencies, setIsLoadingFrequencies] = useState(false);
  const [isError, setIsError] = useState(false);

  const { token } = useSelector((state) => state.auth);
  const userId = useSelector((state) => state.login.user.id);

  const dispatch = useDispatch();

  const submitFormHandler = async (e) => {
    e.preventDefault();
    setResultMessage('');
    setIsError('false');
    if (
      id.length === 0 ||
      billName.length === 0 ||
      payBy.length === 0 ||
      amount.length === 0 ||
      amount === 0 ||
      frequencyId.length === 0
    ) {
      setResultMessage('Name, pay by, amount and frequendy are required');
      setIsError(true);
      return;
    }
    console.log('Addin new bill');
    const newBill = {
      id,
      userId,
      billName,
      payBy,
      amount,
      frequencyId,
      completed,
      completedDate,
      comment,
    };
    console.log(newBill);

    if (path === 'Add bill') {
      const result = await _axiosAddBill(newBill, token);
      console.log(result);
      if (result.status === 200) {
        setResultMessage('Bill added');
        console.log(resultMessage);
        setBillName('');
        setPayBy('');
        setAmount('');
        setFrequencyId('');
        setComment('');
        dispatch(getBills({ userId, token }));
      } else {
        setResultMessage('Something went wrong, check console');
      }
    } else if (path === 'Edit bill') {
      console.log('Saving changes');
      const result = await _axiosUpdateBill(newBill, token);
      if (result.status === 200) {
        setResultMessage('Bill added');
        console.log(resultMessage);
        setBillName('');
        setPayBy('');
        setAmount('');
        setFrequencyId('');
        setComment('');
        dispatch(getBills({ userId, token }));
      } else {
        setResultMessage('Something went wrong, check console');
      }
    }
  };

  //setting up the restrictions for the payBy dates input---------
  useEffect(() => {
    let firstDay = new Date(`${year},${month},01`);
    let myYear = firstDay.getFullYear();
    let myMonth = String(firstDay.getMonth() + 1).padStart(2, '0');
    let myDay = String(firstDay.getDate()).padStart(2, '0');
    setFirstDayOfTheMonth(`${myYear}-${myMonth}-${myDay}`);
    setLastDayOfTheMonth(`${myYear}-${myMonth}-28`);
    console.log(lastDayOfTheMonth);
    const dateInput = document.getElementById('payBy');
    dateInput.setAttribute('min', firstDayOfTheMonth);
    dateInput.setAttribute('max', lastDayOfTheMonth);
  }, [year, month, firstDayOfTheMonth, lastDayOfTheMonth]);
  //setting up the restrictions for the payBy dates input------end

  //   getting all frequencies for the select -----------------------
  useEffect(() => {
    const getAllFrequencies = async () => {
      setIsLoadingFrequencies(true);
      const allFrequencies = await _axiosGetAllFrequencies(token);
      setFrequencies(allFrequencies.data);
      setIsLoadingFrequencies(false);
    };
    getAllFrequencies();
  }, [token]);
  //   getting all frequencies for the select --------------------end

  //   if edit mode ------------------------------------------------
  useEffect(() => {
    if (bill !== null) {
      setId(bill.id);
      setBillName(bill.billName);
      setPayBy(bill.payBy.split('T')[0]);
      setAmount(bill.amount);
      setFrequencyId(bill.frequencyId);
      setCompleted(bill.completed);
      setCompletedDate(
        bill.completedDate === null ? null : bill.completedDate.split('T')[0]
      );
      setComment(bill.comment);
    }
  }, [bill]);
  //   if edit mode ---------------------------------------------end

  return (
    <Card className='rounded-4 bg-dark px-2 text-bg-info col-12'>
      <CardHeader className='text-center text-light'>
        <CardTitle tag='h5'>{path}</CardTitle>
      </CardHeader>
      <CardSubtitle className='text-center my-3'>
        <FaMagic size={30} className='text-success' />
      </CardSubtitle>
      <Form style={{ width: '100%' }} onSubmit={submitFormHandler}>
        {' '}
        <FormGroup floating>
          <Input
            id='name'
            name='name'
            type='text'
            bsSize='sm'
            required
            minLength={3}
            value={billName}
            onChange={(e) => {
              setBillName(e.target.value);
            }}
            invalid={billName.length === 0}
            valid={billName.length > 0}
          />
          <Label htmlFor='name'>Bill name:</Label>
        </FormGroup>{' '}
        <FormGroup floating>
          <Input
            id='payBy'
            name='payBy'
            type='date'
            bsSize='sm'
            min={firstDayOfTheMonth}
            max={lastDayOfTheMonth}
            value={payBy}
            onChange={(e) => setPayBy(e.target.value)}
          />
          <Label htmlFor='payBy'>Pay by:</Label>
        </FormGroup>{' '}
        <FormGroup floating>
          <Input
            required
            id='amount'
            name='amount'
            type='number'
            min='0'
            step='0.01'
            bsSize='sm'
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            invalid={amount === 0 || amount.length === 0}
            valid={amount === 0 && amount.length === 0}
          />
          <Label htmlFor='amount'>Amount:</Label>
        </FormGroup>{' '}
        <FormGroup floating>
          <div className=' d-flex flex-wrap justify-content-center align-items-center'>
            {isLoadingFrequencies ? (
              <Loader />
            ) : (
              <>
                {frequencies.map((frequency) => (
                  <Button
                    key={frequency.id}
                    color='secondary'
                    outline
                    onClick={() => setFrequencyId(frequency.id)}
                    active={frequencyId === frequency.id}
                    className='m-2'
                  >
                    {frequency.name}
                  </Button>
                ))}
              </>
            )}
          </div>
        </FormGroup>{' '}
        <FormGroup floating>
          <Input
            id='comment'
            name='comment'
            type='textarea'
            bsSize='sm'
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <Label htmlFor='name'>Comment:</Label>
        </FormGroup>{' '}
        <p
          className={`${
            isError === true ? 'text-danger' : 'text-success'
          } text-center`}
        >
          <small>{resultMessage}</small>
        </p>
        <div className='row'>
          <Button
            type='submit'
            color='primary'
            outline
            className='col-8 mx-auto mb-3'
          >
            Submit
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default NewBillBody;
