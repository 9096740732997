import React, { useState } from 'react';
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import NewBillBody from './NewBillBody';

import { TbEditCircle } from 'react-icons/tb';

const NewBill = ({ year, month, path, bill = null }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOffcanvas = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <span onClick={toggleOffcanvas}>
        {path === 'Add bill' ? (
          <Button color='dark' className='btn btn-outline-success'>
            {path}
          </Button>
        ) : (
          <TbEditCircle size={20} className='text-info mx-2' />
        )}
      </span>
      <Offcanvas
        isOpen={isOpen}
        toggle={toggleOffcanvas}
        scrollable
        direction='start'
        className=' bg-info bg-gradient bg-opacity-50 border-end border-2 border-light borde'
      >
        <OffcanvasHeader toggle={toggleOffcanvas}></OffcanvasHeader>
        <OffcanvasBody className='d-flex flex-column justify-content-evenly align-items-center'>
          <NewBillBody year={year} month={month} path={path} bill={bill} />
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default NewBill;
