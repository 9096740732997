import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu/NavMenu';

export const Layout = ({ children }) => {
  return (
    <div>
      <NavMenu />
      <Container>{children}</Container>
    </div>
  );
};
