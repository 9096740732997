import React, { useState } from 'react';
import { Row } from 'reactstrap';
import ConnectedChildAccountsTable from './ConnectedChildAccountsTable';

const ConnectedChildAccounts = ({ childUsersConnected }) => {
  const [showConnectedAccounts, setShowConnectedAccounts] = useState(false);
  return (
    <>
      <Row className='my-2 text-center'>
        <p
          style={{ cursor: 'pointer' }}
          className='mx-auto text-decoration-underline'
          onClick={() => {
            setShowConnectedAccounts(!showConnectedAccounts);
          }}
        >
          Show connected accounts
        </p>
      </Row>
      {showConnectedAccounts && (
        <Row>
          <ConnectedChildAccountsTable
            childUsersConnected={childUsersConnected}
          />
        </Row>
      )}
    </>
  );
};

export default ConnectedChildAccounts;
