import React from 'react';
import Loader from '../Loader/Loader';

const ConnectAccoutdButton = ({
  setConnectAccounts,
  connectAccout,
  connectRequestIsLoading,
}) => {
  return (
    <button
      className='btn btn-outline-warning'
      type='button'
      onClick={() => {
        setConnectAccounts(!connectAccout);
      }}
    >
      {!connectRequestIsLoading ? 'Connect to existing account' : <Loader />}
    </button>
  );
};

export default ConnectAccoutdButton;
