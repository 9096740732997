import axios from 'axios';
import { logout } from '../store/billsSlice';

export const _axiosRegisterUser = async (user) => {
  console.log('Registering user:');
  try {
    const response = await axios.post('/api/Auth/register', user);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const _axiosSignup = async (email, password) => {
  console.log('Getting token:');

  const response = await axios.post('/api/Auth/login', { email, password });
  return response;
};

export const _axiosGetUserByEmail = async (email, token) => {
  console.log('Getting user:');
  const response = await axios.get(`/api/User/byEmail/${email}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const _axiosGetBillsByUserId = async (userId, token) => {
  console.log('Getting bills for current user');

  try {
    const response = await axios.get(`/api/Bill/byUserId/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const _axiosUpdateBill = async (newBill, token) => {
  console.log('Updating bill:');
  try {
    const response = await axios.put('/api/Bill/updateBill', newBill, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error.request);
  }
};

export const _axiosGetAllFrequencies = async (token) => {
  try {
    const response = await axios.get(`/api/Frequency`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error.request);
  }
};

export const _axiosAddBill = async (bill, token) => {
  console.log('Adding bill:');
  console.log(bill);
  try {
    const response = await axios.post(`/api/Bill`, bill, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error.request);
  }
};

export const _axiosDeleteBill = async (token, billId) => {
  console.log('Removing bill:');
  try {
    const response = await axios.delete(`api/Bill/${billId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error.request);
  }
};

export const _axiosCreateNextMonthBills = async (token, userId) => {
  console.log('Creating next month bills');

  try {
    const response = await axios.get(`/api/Bill/createNextMonth/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error.request);
  }
};

export const _axiosDeleteLastCreatedMonth = async (token, userId) => {
  console.log('Deleting latest available month');

  try {
    const response = await axios.delete(
      `/api/Bill/deleteLatestMonthsBills/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error.request);
  }
};

export const _axiosConnectToMasterAccountRequest = async (
  token,
  requestData
) => {
  console.log('Sending connect to account handler');
  try {
    const response = await axios.put(`/api/User/connectAccounts`, requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error.request);
    return error.request;
  }
};

export const _axiosCheckExistingConnectAccountRequest = async (
  token,
  userId
) => {
  console.log('Checking for active requests for child user');
  try {
    const response = await axios.get(
      `/api/User/checkForActiveConnectAccountsRequest/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error.request);
    if (error.request.status === 401) {
      return 'logout';
    }
  }
};

export const _axiosCancellConnectAccounsRequest = async (token, userId) => {
  console.log('Cancelling the request');
  try {
    const response = await axios.get(
      `/api/User/cancelConnectAccounts/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error.request);
  }
};

export const _axiosCheckForActiveConnectRequestForMasterUser = async (
  token,
  userId
) => {
  console.log('Checking for active requests for master user');
  try {
    const response = await axios.get(
      `/api/User/checkForActiveConnectRequestForMasterUser/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error.request);
  }
};

export const _axiosRejectRequest = async (
  token,
  userId,
  childUserEmailAddress
) => {
  console.log('Rejecting the request');
  try {
    const response = await axios.get(
      `/api/User/rejectRequest/${userId}/${childUserEmailAddress}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error.request);
    return error.request;
  }
};

export const _axiosAcceptRequest = async (
  token,
  userId,
  childUserEmailAddress
) => {
  console.log('Accepting the request');
  try {
    const response = await axios.get(
      `/api/User/acceptRequest/${userId}/${childUserEmailAddress}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error.request);
    return error.request;
  }
};

export const _axiosGetAllConnectedChildUsers = async (token, userId) => {
  console.log('Checkig for connected accounts');
  try {
    const response = await axios.get(`/api/User/getAllChildUsers/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.log(error.request.response);
    return error.request;
  }
};

export const _axiosDisconnectChildUserAsync = async (
  token,
  disconnectRequest
) => {
  console.log('Disconnecting user');
  try {
    const response = await axios.post(
      `/api/User/disconnectChild`,
      disconnectRequest,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error.request);
  }
};
