import React, { useEffect, useState } from 'react';
import { getAvailableMonthsForGivenYear } from '../../helperFunctions/billsSorting';

import BillsForMonth from './BillsForMonth';

import { monthNames } from '../../utils/monthNames';
import NewBill from './AddNewBill/NewBill';

import { motion } from 'framer-motion';

const SingleYear = ({ year, bills }) => {
  const [billsForMonthVisible, setBillsForMonthVisible] = useState('');
  const [allAvailableMonthsForTheYear, setAllAvailableMonthsForTheYear] =
    useState([]);

  useEffect(() => {
    if (bills !== null) {
      const monthsInYear = getAvailableMonthsForGivenYear(bills, year);
      setAllAvailableMonthsForTheYear(monthsInYear);
    }
  }, [bills, year]);

  //creating onClick event only for parent element, exclucing the children ---------

  return (
    <div>
      <li className='list-group-item bg-dark  border-success border-4 rounded-2 my-5'>
        <h2
          style={{ cursor: 'pointer' }}
          initial={{
            opacity: 0,
          }}
          animate={{ opacity: 1 }}
          className=' text-success'
        >
          {year}
        </h2>
        <ul className='list-group list-group-flush'>
          {allAvailableMonthsForTheYear.map((month, i) => (
            <li
              key={i}
              className=' my-4 list-group-item  border-2 border-info text-white '
            >
              <div id='parent-element' className=' row'>
                <p className='col-4'> {}</p>
                <motion.h2
                  className='col-4 text-center text-warning'
                  style={{ cursor: 'pointer' }}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{ opacity: 1 }}
                  onClick={() => {
                    setBillsForMonthVisible(
                      billsForMonthVisible !== `${year}-${month}`
                        ? `${year}-${month}`
                        : ''
                    );
                  }}
                >
                  {monthNames[month - 1]}
                </motion.h2>
                <div className='col-4 text-end'>
                  <NewBill year={year} month={month} path='Add bill' />
                </div>
              </div>
              <motion.ul
                className='list-group list-group-flush my-2'
                hidden={`${year}-${month}` !== billsForMonthVisible}
              >
                <BillsForMonth year={year} month={month} />
              </motion.ul>
            </li>
          ))}
        </ul>
      </li>
      {/*  */}

      {/*  */}
    </div>
  );
};

export default SingleYear;
