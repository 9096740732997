export const getAllAvailableYears = (bills) => {
  const years = [];
  bills.forEach((bill) => {
    if (!years.includes(new Date(bill.payBy).getFullYear())) {
      years.push(new Date(bill.payBy).getFullYear());
    }
  });

  return years;
};

export const getAvailableMonthsForGivenYear = (bills, year) => {
  const months = [];
  bills.forEach((bill) => {
    if (new Date(bill.payBy).getFullYear() === year) {
      if (!months.includes(new Date(bill.payBy).getMonth() + 1)) {
        months.push(new Date(bill.payBy).getMonth() + 1);
      }
    }
  });
  return months;
};

export const getAllBillsForGivenMonthAndYear = (month, year, bills) => {
  const billsForMonth = [];

  const startDate = `${year}-${month.toString().padStart(2, '0')}-01`;

  const endDate = `${year}-${month.toString().padStart(2, '0')}-29`;

  bills.forEach((bill) => {
    if (bill.payBy >= startDate && bill.payBy < endDate) {
      billsForMonth.push(bill);
    }
  });

  return billsForMonth;
};
