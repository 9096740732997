import { Home } from './components/Home';
import LoginRegister from './components/Login_Register/LoginRegister';

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: '/api/User',
    element: <Home />,
  },
  {
    path: '/api/Auth',
    element: <Home />,
  },
  {
    path: '/api/Bill',
    element: <Home />,
  },
  {
    path: '/api/Frequency',
    element: <Home />,
  },

  {
    path: '/Auth/login',
    element: <LoginRegister path={'Login'} />,
  },
  {
    path: '/Auth/register',
    element: <LoginRegister path={'Register'} />,
  },
  {
    path: '/Auth/logout',
    element: <LoginRegister path={'Logout'} />,
  },
  // {
  //   path: '/fetch-data',
  //   element: <FetchData />
  // }
];

export default AppRoutes;
