import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarText,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useSelector } from 'react-redux';

import { AiOutlineLogout } from 'react-icons/ai';
import { IoMdLogIn } from 'react-icons/io';
import { GiArchiveRegister } from 'react-icons/gi';

import { motion } from 'framer-motion';

export const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);

  const { user } = useSelector((state) => state.login);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <motion.header
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ delay: 0.5, duration: 0.5, ease: 'easeInOut' }}
    >
      <Navbar
        className='navbar-expand-sm bg-dark navbar-toggleable-sm ng-black border-bottom box-shadow mb-3'
        container
        dark
      >
        <NavbarBrand
          tag={Link}
          to='/'
          className='d-flex flex-column align-items-center'
        >
          <img src='/img/billsLogo.png' width={150} alt='Bill Manager' />
          Bill Manager
        </NavbarBrand>
        <NavbarText>
          {user !== null && (
            <NavItem className='d-flex align-items-end'>
              <p className='text-center'>
                Welcome <br />{' '}
                <span className=' text-body-tertiary text-capitalize'>
                  {user.username}
                </span>
              </p>
            </NavItem>
          )}
        </NavbarText>
        <NavbarToggler onClick={toggleNavbar} className='mr-2' />
        <Collapse
          className='d-sm-inline-flex flex-sm-row-reverse'
          isOpen={collapsed}
          navbar
        >
          <ul className='navbar-nav flex-grow'>
            {/* <NavItem>
              <NavLink tag={Link} className='text-white-50' to='/'>
                Home
              </NavLink>
            </NavItem> */}

            {user === null ? (
              <>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className=' btn btn-info mx-3 text-white px-5 my-2'
                    to='/Auth/login'
                  >
                    Login
                    <IoMdLogIn size={30} className='mx-3' />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className='btn btn-info mx-3 text-white px-5 my-2'
                    to='/Auth/register'
                  >
                    Register
                    <GiArchiveRegister size={30} className='mx-3' />
                  </NavLink>
                </NavItem>
              </>
            ) : (
              <NavItem>
                <NavLink
                  tag={Link}
                  className='btn btn-info mx-3 text-white px-5 my-2'
                  to='/Auth/logout'
                >
                  Logout
                  <AiOutlineLogout size={30} className='mx-3' />
                </NavLink>
              </NavItem>
            )}
          </ul>
        </Collapse>
      </Navbar>
    </motion.header>
  );
};
