import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAvailableYears } from '../../helperFunctions/billsSorting';
import SingleYear from './SingleYear';
import {
  _axiosAddBill,
  _axiosCancellConnectAccounsRequest,
  _axiosCheckExistingConnectAccountRequest,
  _axiosCheckForActiveConnectRequestForMasterUser,
  _axiosConnectToMasterAccountRequest,
} from '../../API Calls/functions';
import { getBills } from '../../store/billsSlice';

import Loader from '../Loader/Loader';
import { Button, Card, Row } from 'reactstrap';
import ConnectAccountsForm from '../ConnectAccoutns/ConnectAccountsForm';
import ConnectAccoutdButton from '../ConnectAccoutns/ConnectAccoutdButton';

import { ImWarning } from 'react-icons/im';
import Request from '../RequestToJoin/Request';

import { logout as removeToken } from '../../store/authSlice';
import { logout as removeUser } from '../../store/userSlice';
import { logout as removeBills } from '../../store/billsSlice';

const BillList = () => {
  const [availableYears, setAvailableYears] = useState([]);
  const [connectAccout, setConnectAccounts] = useState(false);
  const [masterPersonEmailAddress, setMasterPersonEmailAddress] = useState('');
  const [connectRequestIsLoading, setConnectRequestIsLoadnig] = useState(false);
  const [checkRequestsResponseData, setCheckRequestsResponseData] =
    useState(null);
  const [
    checkRequestResponseDataForMasterUser,
    setCheckRequestResponseDataForMasterUser,
  ] = useState(null);
  const [showRequestBody, setShowRequestBody] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    bills,
    isLoading: isLoadingBills,
    isError: isErrorBills,
  } = useSelector((state) => state.billsData);
  const userId = useSelector((state) => state.login.user.id);
  const { token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    const checkForActiveConnectRequestForMasterUser = async () => {
      const response = await _axiosCheckForActiveConnectRequestForMasterUser(
        token,
        userId
      );

      setCheckRequestResponseDataForMasterUser(response.data);
    };
    if (bills !== null) {
      const years = getAllAvailableYears(bills);
      setAvailableYears(years);
      checkForActiveConnectRequestForMasterUser();
    }
  }, [bills, token, userId]);

  const addFirstBill = async () => {
    const newBill = {
      id: '00000000-0000-0000-0000-000000000000',
      userId,
      billName: 'Temporory Bill',
      payBy: new Date().toISOString(),
      amount: 1,
      frequencyId: '8EE6175B-FFF8-4FC1-AFCE-BC1F6EC6099E', //TODO - change this
      completed: false,
      completedDate: null,
      comment: null,
    };
    console.log(newBill);
    var response = await _axiosAddBill(newBill, token);
    console.log(response);
    dispatch(getBills({ userId, token }));
  };

  const connectToAccountRequestHandler = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setConnectRequestIsLoadnig(true);
    const requestData = {
      childUserId: userId,
      masterUserEmailAddress: masterPersonEmailAddress,
    };
    const result = await _axiosConnectToMasterAccountRequest(
      token,
      requestData
    );
    if (result.status === 200) {
      setCheckRequestsResponseData({ requestExist: true, verified: false });
    } else {
      setErrorMessage(result.responseText);
    }
    console.log(result);
    setConnectRequestIsLoadnig(false);
  };

  //checking existiong connect accoutns request--------------------
  useEffect(() => {
    const checkExistingRequests = async () => {
      const result = await _axiosCheckExistingConnectAccountRequest(
        token,
        userId
      );
      if (result === 'logout') {
        dispatch(removeToken());
        dispatch(removeUser());
        dispatch(removeBills());
      }
      setCheckRequestsResponseData(result.data);
    };
    if (bills === null) {
      checkExistingRequests();
    }
  }, [bills, token, userId]);
  //checking existiong connect accoutns request-----------------end

  const cancelRequestHandler = async () => {
    console.log('Cancelling the request');
    const result = await _axiosCancellConnectAccounsRequest(token, userId);
    if (result.status === 200) {
      setCheckRequestsResponseData({ requestExist: false, verified: false });
    }
  };
  return (
    <>
      {isLoadingBills ? (
        <Loader />
      ) : (
        <>
          {checkRequestResponseDataForMasterUser !== null &&
            checkRequestResponseDataForMasterUser.requestExist && (
              <Card className='mt-4 col-8 p-1 mx-auto border-1 border-danger'>
                <button
                  className='col-12 mx-auto btn btn-outline-warning'
                  onClick={() => {
                    setShowRequestBody(!showRequestBody);
                  }}
                >
                  <ImWarning color='red' size={30} className='my-3' />
                  <br /> You have a request!
                </button>

                <Row>
                  {showRequestBody && (
                    <Request
                      checkRequestResponseDataForMasterUser={
                        checkRequestResponseDataForMasterUser
                      }
                    />
                  )}
                </Row>
              </Card>
            )}
          <>
            {bills !== null ? (
              <ul className='list-group list-group-flush'>
                {availableYears.map((year, i) => (
                  <SingleYear key={i} year={year} bills={bills} />
                ))}
              </ul>
            ) : (
              <>
                {checkRequestsResponseData !== null &&
                checkRequestsResponseData.requestExist === false ? (
                  <>
                    <div className='row my-5 mx-auto'>
                      <button
                        onClick={() => {
                          addFirstBill();
                        }}
                        className='btn btn-lg btn-outline-primary'
                      >
                        Add your first bill for the current month
                      </button>
                    </div>
                    <Row className='mx-auto'>
                      <h6 className='col-12 text-info text-center'>
                        You can connect to an{' '}
                        <span className='text-warning'>existing account</span>.{' '}
                        <br /> Press the button below to request connection.{' '}
                        <br /> The owner of the other account will have to{' '}
                        <span className='text-warning'>accept the request</span>{' '}
                        in his own app, and then both of you will have the full
                        access to the account.
                      </h6>
                    </Row>
                    <Row className='mx-auto my-2'>
                      <ConnectAccoutdButton
                        setConnectAccounts={setConnectAccounts}
                        connectAccout={connectAccout}
                        connectRequestIsLoading={connectRequestIsLoading}
                      />
                    </Row>
                    <Row className='mx-auto my-4'>
                      {connectAccout && (
                        <ConnectAccountsForm
                          setConnectAccounts={setConnectAccounts}
                          connectToAccountRequestHandler={
                            connectToAccountRequestHandler
                          }
                          masterPersonEmailAddress={masterPersonEmailAddress}
                          setMasterPersonEmailAddress={
                            setMasterPersonEmailAddress
                          }
                          connectRequestIsLoading={connectRequestIsLoading}
                          errorMessage={errorMessage}
                        />
                      )}
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className='mx-auto'>
                      {checkRequestsResponseData !== null &&
                        checkRequestsResponseData.requestExist === true && (
                          <h4 className=' text-center mt-5'>
                            Waiting the request to be accepted
                          </h4>
                        )}
                    </Row>
                    <Row>
                      <Button
                        color='outline-danger mt-5'
                        onClick={cancelRequestHandler}
                      >
                        Cancel the request
                      </Button>
                    </Row>
                  </>
                )}
              </>
            )}
          </>
        </>
      )}
    </>
  );
};

export default BillList;
